import React from 'react';
import { FidgetSpinner } from 'react-loader-spinner';

const Loading = () => {
  return (
    <div
      style={{
        position: 'fixed', // Fixed position
        top: 0,
        left: 0,
        width: '100%', // Full width
        height: '100%', // Full height
        zIndex: 9999, // Make it cover everything
        backgroundColor: 'rgba(255,255,255,0.8)', // White background with opacity
      }}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <FidgetSpinner
          type='Puff'
          color='#e601fe'
          backgroundColor='#e601fe'
          height={100}
          width={100}
        />
      </div>
    </div>
  );
};

export default Loading;
