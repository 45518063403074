/* eslint-disable jsx-a11y/anchor-is-valid */
// a play store for games.
// import and use bootstrap

// create a navbar with a logo, login and sign up buttons
// create a carousel slider with 3 images
// create a row with 4 game categories. each category has an icon and a name

import { useEffect, useMemo, useState } from "react";
import "./Home.css";
import "./Games.scss";
import "bootstrap/dist/js/bootstrap.js";
// fontawesome
import "@fortawesome/fontawesome-free/css/all.min.css";
// import bootstrap js
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import GAMES from "../data/games.json";

import Lottie from "react-lottie";
import animationData from "../data/phone.json";
import Loading from "../loader";
import { useCallback } from "react";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";



function GamesPage() {
  const [searchParams] = useSearchParams();
  const param = useMemo(() => {
    const x = {}
    for (let [key, value] of searchParams.entries()) {
      x[key] = value;
    }
    return x
  }, [searchParams]);

  const dataModalRef = useRef();

  const [games] = useState(GAMES);
  const [categories] = useState([
    {
      id: 2,
      name: "Learning",
      icon: "/images/icons/book.png",
    },
    {
      id: 3,
      name: "Arcade",
      icon: "/images/icons/spaceship.png",
    },
    {
      id: 4,
      name: "Adventure",
      icon: "/images/icons/location.png",
    },
    {
      id: 5,
      name: "Puzzle",
      icon: "/images/icons/jigsaw.png",
    },
    {
      id: 6,
      name: "Sports",
      icon: "/images/icons/sports.png",
    },
    {
      id: 1,
      name: "Racing",
      icon: "/images/icons/race.png",
    },
    {
      id: 7,
      name: "Board",
      icon: "/images/icons/board-game.png",
    },
  ]);

  const [promotions, setPromotions] = useState([]);
  const [loading] = useState(false);
  const [, setRedirect] = useState("");

  const ipAddress = useRef()
  const msisdnHash = useRef()
  const subscribed = useRef(false)

  useEffect(() => {
    // Update promotional banners using random games

    const randomPromotions = [];

    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * games.length);
      randomPromotions.push(games[randomIndex]);
    }

    setPromotions(randomPromotions);

    // initialize carousel
    const myCarousel = document.querySelector("#carouselExampleControls");
    // const carousel = new bootstrap.Carousel(myCarousel, {
    //   interval: 2000,
    //   wrap: true,
    // });
  }, [games]);

  const stack = useRef([])

  async function fetchWithTimeout(resource, options = {}) {
    try {
      stack.current.push(1)
      const { timeout = 8000 } = options;

      delete options.timeout

      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);

      const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
      });

      clearTimeout(id);

      return response;
    } catch (error) {
      throw error
    } finally {
      stack.current.pop()
    }
  }

  const cbReq = useRef(false)

  const checkSubscription = useCallback(async () => {
    console.log('cALLED')

    if (cbReq.current) {
      console.log('checkSubscription ongoing request, skipping this')
      return
    }

    cbReq.current = true

    try {
      if (!msisdnHash.current) return;

      let body = {
        msisdn: msisdnHash.current,
        channel: "fungames",
      };

      const response = await fetchWithTimeout("http://api.fun-games.co.ke/check-subscribe-he", {
        timeout: 60000,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        return
      }

      const data = await response.json();
      if (data.Subscribed === 1) {
        subscribed.current = true
      } else {
        body = {
          ...body,
          ip_address: ipAddress.current,
          command: "subscribe",
          params: param
        };
	// Request consent
        //Added gtag to report subscription event
        //Added by Dominic
	var dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push ({
            'event' : 'subscribe',
            'timeOnPage' : new Date().getTime()
        });
        await fetchWithTimeout("http://api.fun-games.co.ke/activate-he", {
          timeout: 60000,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
      }
    } catch (error) {
      console.log('oops', error)
    } finally {
      cbReq.current = false
    }
  }, [param]);

  const ipRequest = useRef(false)

  const loadIpAddress = useCallback(async () => {
    if (ipRequest.current) {
      console.log('ip ongoing request, skipping this')
      return
    }

    ipRequest.current = true

    try {
      const response = await fetchWithTimeout("http://api.ipify.org?format=json");
      if (!response.ok) {
        return
      }
      const { ip } = await response.json();
      ipAddress.current = ip
      checkSubscription();
    } catch (error) {
      console.log(error);
    } finally {
      ipRequest.current = false
    }
  }, [checkSubscription]);

  const heRequest = useRef(false)

  const loadHeaderEnrichment = useCallback(async () => {
    if (heRequest.current) {
      console.log('loadHeaderEnrichment ongoing request, skipping this')
      return
    }

    heRequest.current = true

    try {
      hideDataModal();
      const response = await fetchWithTimeout("http://header.safaricombeats.co.ke/");
      if (!response.ok) {
        return
      }
      const text = await response.text();
      const dp = new DOMParser();
      const data = dp.parseFromString(text, "text/xml");
      const responseCode = data.getElementsByTagName("ns0:ResponseCode")[0]?.textContent;
      if (responseCode === "0") {
        hideDataModal();
        const hash = data.getElementsByTagName("ns1:MsisdnHash")[0]?.textContent;
        msisdnHash.current = hash
        checkSubscription();
      } else {
        showDataModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      heRequest.current = false
    }
  }, [checkSubscription]);

  useEffect(() => {
    if (stack.current.length === 0) {
      loadIpAddress();
      loadHeaderEnrichment();
    }
  }, [loadIpAddress, loadHeaderEnrichment]);

  const showDataModal = () => {
    const modalEle = dataModalRef.current;
    const bsModal = new window.bootstrap.Modal(modalEle);
    bsModal.show();
  };

  const hideDataModal = () => {
    const modalEle = dataModalRef.current;
    const bsModal = window.bootstrap.Modal.getInstance(modalEle);
    if (bsModal == null) return;
    bsModal.hide();
  };

  return (
    <div className="App">
      {loading && <Loading />}

      <nav className="navbar navbar-expand-lg navbar-light  shadow">
        <div className="container d-flex justify-content-between align-items-center">
          <a className="navbar-brand fw-bold text-white" href="#">
            FunGames
          </a>

          <div className="d-flex">
            <a className="btn btn-sm btn-gradient " href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Login
            </a>
            {/* <a className='btn btn-sm btn-gradient' href='#'>
              Sign Up
            </a> */}
          </div>
        </div>
      </nav>

      <div className="container my-4">
        {/* game category icons */}

        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            overflowX: "auto",
          }}
        >
          {categories.map((category, index) => (
            <a
              key={index}
              href={`#${category.name}`}
              style={{
                textDecoration: "none",
              }}
              className=" d-flex flex-column align-items-center mx-3 mx-md-4 mb-2"
            >
              <img src={category.icon} width={40} alt="IMG" />

              <div className="mt-1">
                <small
                  className="text-uppercase fw-bold"
                  style={{
                    fontSize: "0.6rem",
                    opacity: 0.8,
                    color: "#fff",
                  }}
                >
                  {category.name}
                </small>
              </div>
            </a>
          ))}
        </div>

        {/* promotional carousel slider */}
        <div className="row my-4">
          <div className="col-12">
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {promotions.map((promotion, index) => (
                  <div key={index} className="carousel-item d-flex justify-content-center">
                    <img src={promotion.banner} className="d-block w-100 rounded banner-img" alt="..." />

                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="text-shadow fw-bold">{promotion.name}</h5>
                      <p className="text-shadow">{promotion.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* category rows with game cards. game cards have game banner, play now and demo buttons*/}

        <div className="row">
          <div className="col-12 my-4">
            <p className="fw-bold m-0 text-uppercase">
              <i className="fas fa-gamepad me-2"></i>
              <small>All Games</small>
            </p>

            <div className="row">
              {games.map((game, i) => (
                <div key={i} className="col-6 col-md-4 col-lg-4 my-1 p-1">
                  <Card game={game} setRedirect={setRedirect} isSubscribed={subscribed} />
                </div>
              ))}
            </div>
          </div>

          {/* loop through categories and filter games for each category */}
          {categories.map((category, i) => (
            <div key={i} className="col-12 my-4" id={category.name}>
              <div className="fw-bold m-0 text-uppercase mb-2 d-flex align-items-center">
                <img src={category.icon} width={30} className="me-2" alt="-" />
                <div>{category.name}</div>
              </div>

              <div className="row">
                {games
                  .filter((game) => game.category === category.name)
                  .map((game, i) => (
                    <div key={i} className="col-6 col-md-4 col-lg-4 my-1 p-1">
                      <Card game={game} setRedirect={setRedirect} isSubscribed={subscribed} />
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* footer */}
      <div className="bg-dark text-white py-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 ">
              <p className="fw-bold">FunGames</p>
              <p>Fun Games is a product of Onfon Media Limited</p>

              <div className="d-flex">
                <a href="#" className="me-2">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="me-2">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="me-2">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#" className="me-2">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 ">
              <p className="fw-bold">Quick Links</p>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>

            <div className="col-12 col-md-6 col-lg-3 ">
              <p className="fw-bold">Contact Us</p>
              {/* <p>
                <i className="fas fa-phone-alt me-2"></i> +254 726 354 124
              </p> */}
              <p>
                <i className="fas fa-envelope me-2"></i>

                <a href="mailto:info@fun-games.com">info@fungames.com</a>
              </p>

              {/* <p>
                <i className="fas fa-map-marker-alt me-2"></i>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="dataModal" ref={dataModalRef} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <p>Please turn on mobile data to continue</p>
              <button className="w-full" onClick={loadHeaderEnrichment}>
                Retry
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Card = ({ game, setRedirect, isSubscribed }) => {
  const goToGame = (href) => {
    window.location = href;
  };

  return (
    <div className="" onClick={() => goToGame(game.play)}>
      <img src={game.banner} className="game-card card-img-top" alt="..." />
      <div className="card-body">
        {/* <h5 className='card-title'>{game.name}</h5>
         <p className='card-text'>{game.description}</p> */}
        {/* <div className='d-flex justify-content-between p-1 pt-2'>
          <button
            type='button'
            onClick={() => setRedirect(game.play)}
            className='btn btn-sm btn-gradient'
            >
            Play Now
          </button>
          <a
            href={game.demo}
            className='btn btn-sm btn-outline-light'
            // data-bs-toggle='modal'
            // data-bs-target='#exampleModal'
          >
            Demo
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default GamesPage;
