// import HomePage from "./pages/HomePage";
import GamesPage from "./pages/GamesPage";
import { createBrowserRouter } from "react-router-dom";
// import LoadingPage from "./pages/LoadingPage";

const router = createBrowserRouter([
  { path: "/", element: <GamesPage /> },
  { path: "/loading", element: <GamesPage /> },
  { path: "/games", element: <GamesPage /> },
]);

export default router;
